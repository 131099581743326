var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"totalRows":_vm.totalRecords,"pagination-options":{
        enabled: true,
        nextLabel: _vm.$t('Next'),
        prevLabel:  _vm.$t('Prev'),
        rowsPerPageLabel: _vm.$t('Rows per page'),
        ofLabel: _vm.$t('of'),
        perPage: _vm.currentPerPage,
        dropdownAllowAll: false,
        perPageDropdown: [10,20,30,40,50],
        setCurrentPage: _vm.currentPage,

      }},on:{"on-page-change":_vm.onChangePage,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'created_datetime')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.formatDateTime(props.row.created_datetime))+" ")]):(props.column.field === 'cmd')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t(props.row.cmd))+" ")]):(props.column.field === 'full_name')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.row.full_name)+" ")]):(props.column.field === 'device_name')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.row.device_name)+" ")]):(props.column.field === 'avatar')?_c('div',{staticClass:"avatar"},[_c('b-avatar',{ref:"previewEl",attrs:{"text":_vm.avatarText(props.row.full_name),"size":"40px","circle":""}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }