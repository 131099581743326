<template>
  <b-card no-body>
    <b-card-body>
      <vue-good-table
        mode="remote"
        @on-page-change="onChangePage"
        @on-per-page-change="onPerPageChange"
        :columns="columns"
        :rows="rows"
        :totalRows="totalRecords"
        :pagination-options="{
          enabled: true,
          nextLabel: $t('Next'),
          prevLabel:  $t('Prev'),
          rowsPerPageLabel: $t('Rows per page'),
          ofLabel: $t('of'),
          perPage: currentPerPage,
          dropdownAllowAll: false,
          perPageDropdown: [10,20,30,40,50],
          setCurrentPage: currentPage,

        }"
      >
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field === 'created_datetime'"
            class="text-nowrap"
          >
            {{ formatDateTime(props.row.created_datetime) }}
          </span>
          <span
            v-else-if="props.column.field === 'cmd'"
            class="text-nowrap"
          >
            {{ $t(props.row.cmd) }}
          </span>
          <span
            v-else-if="props.column.field === 'full_name'"
            class="text-nowrap"
          >
            {{ props.row.full_name }}
          </span>
          <span
            v-else-if="props.column.field === 'device_name'"
            class="text-nowrap"
          >
            {{ props.row.device_name }}
          </span>
          <div v-else-if="props.column.field === 'avatar'"
          class="avatar">
            <b-avatar
          ref="previewEl"
          :text=avatarText(props.row.full_name)
          size="40px"
          circle
        />
          </div>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BBadge,
  BDropdown,
  BDropdownItem,
  BModal,
  BAvatar
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import RemoteFoodCarousel from "./RemoteFoodCarousel.vue";
import RemoteProgramasTemp from "./RemoteProgramasTemp.vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    RemoteFoodCarousel,
    RemoteProgramasTemp,
    VueGoodTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    VueJsonPretty,
    BModal,
    BAvatar,
  },
  props: ["deviceId", "interval"],
  directives: {
    Ripple,
  },
  data() {
    return {
      commandStatus: {
        waiting: "Waiting",
        acknowledged: "Acknowledged",
        failed: "Failed",
        timeout: "Timeout",
      },
      columns: [
      {
          field: "avatar",
          label: "",
          width: '30px',
        },
        {
          field: "full_name",
          label: this.$t("NAME"),
        },
        {
          field: "device_name",
          label: this.$t("DEVICE"),
        },
        {
          field: "cmd",
          label: this.$t("ACTIONS TAKEN"),
        },
        {
          field: "created_datetime",
          label: this.$t("INTERVENTION DATE"),
        },
      ],
      rows: [],
      modalShow: false,
      currentFeedbackData: {},
      avatarText,
      totalRecords: 0,
      next: null,
      previous: null,
      currentPage:1,
      currentPerPage: 10,
      endpoint:'',

      
    };
  },
  methods: {
    formatDateTime(datetime) {
      return new Date(datetime).toLocaleString();
    },
    getDeviceCommands(newEndpoint = '') {
      this.rows = []
      if(newEndpoint){
        this.endpoint = newEndpoint
      }
      else if (this.deviceId)
          this.endpoint = `${process.env.VUE_APP_API_URL}api/commands/?device=${this.deviceId}&interval=${this.interval}&limit=${this.currentPerPage}`;
      else
          this.endpoint = `${process.env.VUE_APP_API_URL}api/commands/?interval=${this.interval}&limit=${this.currentPerPage}`;
      
      this.$http.get(this.endpoint).then((res) => {
        this.rows = res.data.results;
        this.next = res.data.next
        this.previous = res.data.previous
        this.totalRecords = res.data.count
      });
    },
    openModal(feedback) {
      this.modalShow = true;
      this.currentFeedbackData = JSON.parse(feedback.replace(/'/g, '"'));
    },
    onPerPageChange(event)
    {
      this.currentPerPage = event.currentPerPage
      this.getDeviceCommands()
    },
    onChangePage(event)
    {
      if (event.currentPage > event.prevPage){
        this.setNextasCurrent()
        this.currentPage = event.currentPage
      }
      else{
        this.setPreviousasCurrent()
        this.currentPage = event.currentPage
      }
      if(event.currentPage != this.currentPage)
      {
        this.getDeviceCommands()
      }
    },
    setNextasCurrent()
    {
      this.getDeviceCommands(this.next)
    },
    setPreviousasCurrent()
    {
      this.getDeviceCommands(this.previous)
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        waiting: "light-waiting",
        failed: "light-alerta",
        acknowledged: "light-activo",
        timeout: "light-offline",
        /* eslint-enable key-spacing */
      };
      return (status) => statusColor[status];
    },
  },
  watch: {
    deviceId() {
      this.getDeviceCommands();
    },
    interval(){
      this.currentPage = 1
      this.getDeviceCommands();
    }
  },
  created() {
    this.getDeviceCommands();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>