<template>
  <div>
    <b-row class="match-height">
      <b-col cols="12" xl="12">
        <b-card no-body class="history-top">
            <b-card-body class="history-body">
              <b-row style="display: flex; width:100%">
                <b-col cols="12" xl="6">
                  <div class="history-text">
                    <span class="history-icon">
                      <feather-icon  icon="ClockIcon" />
                    </span>
                    <span class="history-title">
                      {{ $t("History") }}
                    </span>
                  </div>
                </b-col>
                <b-col cols="12" xl="6">
                  <div class="w-100 pt-2 d-sm-none"></div>
                  <div class="history-selects">
                    <b-row>
                      <b-col cols="12" xl="6" class="history-select-mobile">
                        <v-select
                          class="select-device"
                          :options="deviceList"
                          label="description"
                          :reduce="(device) => device.id"
                          v-model="selectedDevice"
                          :placeholder="$t('Device')"
                          :searchable="false"
                        >
                          <template slot="option" slot-scope="option">
                            {{ option.model }} {{ option.description }}
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            {{ option.model }} {{ option.description }}
                          </template>
                        </v-select>
                      </b-col>
                      <b-col cols="12" xl="6">
                        <v-select
                          :searchable="false"
                          class="select-device"
                          :options="monthRange"
                          v-model="filter_time"
                          :placeholder="$t('Last Month')"
                        >
                        </v-select>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <remote-command-feedback :deviceId="selectedDevice" :interval="(filter_time ? filter_time.value : '')"/>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { codeRowDetailsSupport } from "./code";
import RemoteCommandFeedback from "../remote/Remote-2/RemoteCommandFeedback.vue";
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BPagination,
    vSelect,
    RemoteCommandFeedback,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedDevice: null,
      filter_time: null,
      perPage: 1,
      totalRows: 3,
      currentPage: 1,
      selectedMonth: this.$t("Last Month"),
      monthRange: [
        {value:"one_month", label:this.$t("Last Month")},
        {value:"six_months", label:this.$t("6 Months Ago")},
        {value:"one_year", label:this.$t("Last Year")},
      ],
      selectedLocal: this.$t("Site"),
      /* eslint-disable global-require */

      codeRowDetailsSupport,
    };
  },
  computed: {
    deviceList() {
      return this.$store.state.devices.devicesList;
    },
  },
  created() {
    this.$store.dispatch("devices/getRegisteredDevices", { context: this });
  },
};
</script>
<style scoped>
.select-device {
  width: 100%;
  border-radius: 6px;
  background: #fff;
  margin-right: 10px;
  min-width: 150px;
  max-width: 300px;
}

@media (max-width: 500px) {
  .history-top{
    height: 175px;
  }

  .history-body{
    padding-right: 0px;
  }
  
  .history-select-mobile{
    margin-bottom: 1rem;
  }
}
</style>